import React from 'react'

import { I18n } from '@front/volcanion'
import { TextField } from '@front/squirtle'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

const Row1 = () =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <TextField
        label={I18n.t('title.label', { count: 1 })}
        name='title'
        required
      />
    </Grid>
  </Grid>

const Row2 = () =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs>
      <TextField
        label={I18n.t('message.label', { count: 1 })}
        name='body'
        required
      />
    </Grid>
  </Grid>

const PrimarySection = () => <>
  <StepLabel>
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
      <Typography variant='h5'>{I18n.t('message.label', { count: 1 })}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Box rowSpacing={3} sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 />
        <Row2 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
