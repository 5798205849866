import React from 'react'
import { I18n } from '@front/volcanion'
import UserUtils from '@front/volcanion/utils/user'

import { Typography, Grid, Box } from '@mui/material'

import { TextField, MenuSelectorField, DateTimeField, ModelAutocompleteField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('incident.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        name={'name'}
        label={I18n.t('incident.type')}
        options={[
          { value: 'driver_request_for_a_not_loaded', label: I18n.t('event.driver_request_for_a_not_loaded') },
          { value: 'driver_request_for_redispatch', label: I18n.t('event.driver_request_for_redispatch') },
          { value: 'driver_request_for_replacement', label: I18n.t('event.driver_request_for_replacement') },
          { value: 'driver_request_to_be_called', label: I18n.t('event.driver_request_to_be_called') },
          { value: 'order_not_served', label: I18n.t('event.order_not_served') },
          { value: 'pairing_request', label: I18n.t('event.pairing_request') },
        ]}
        multiple
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'driver'}
        label={I18n.t('driver.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'order.do'}
        label={I18n.t('user.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { customerinfo: { client_number: { startsWith: _.split(q, ' ') } } },
            { customerinfo: { name: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }
          ]
        })}
        labelKeys={['label']}
        multiple={false}
        loadOnFocus={false}
        config={{
          forced_filter: { driverinfo: null },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.customerinfo?.client_number }),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
    <Grid item xs><TextField name={'order.do.auth.gsm'} label={I18n.t('phone.label', { count: 1 })} /></Grid>
    <Grid item xs><TextField name={'order.order_client_id'} label={I18n.t('order.client_id', { count: 1 })} /></Grid>

  </Grid>

const Row3 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <DateTimeField
        name='createdAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name='createdAt_end'
        dateProps={{
          label: I18n.t('prefix.to'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.at'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent='center'>
    <Grid item xs={10}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 2 }}>
        <Grid container rowSpacing={3}>
          <Title />
          <Row1 />
          <Row2 />
          <Row3 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)

